import React from "react";
import { POSITION_CURSOR, RESIZING_POSITION } from "../constants";

const STYLE_CIRCLE = {
    fill: "#0096fd",
    stroke: "#0096fd",
    cursor: "ew-resize",
};

const STYLE_CIRCLE2 = {
    fill: "none",
    stroke: "#0096fd",
    cursor: "ew-resize",
};


const STYLE_RESIZE_SQUARE = {
    fill: "#0096fd",
    stroke: "#0096fd",
    cursor: "ew-resize",
};

const getResizeSquareStyle = (cursor) => {
    return {
        ...STYLE_RESIZE_SQUARE,
        // cursor: 'url("https://drive.google.com/uc?export=download&id=1yXrHkRGPqO3IB_TY8t520jme_mQz0zAm") 16 16, auto',
        cursor
    };
};

const STANDARD_RESIZE_SHAPE = {
    width: 10,
    height: 10,
};

function getResizingDetail({ item }) {
    try {
        const { properties, x, y } = item.toJS();
        const { width, depth, height } = properties;
        const { length: widthLength } = width;
        const { length: heightLength } = depth;
        const enableResizing = widthLength > 0 && heightLength > 0
        return {
            enableResizing,
            width,
            depth,
            widthLength,
            heightLength,
            center: {
                x,
                y
            }
        };
    } catch (error) {
        return {
            enableResizing: false,
        }
    }

}

function getResizeSquaresConfiguration({ item }) {
    const { properties, rotation } = item;
    const widthLength = properties.getIn(['width', 'length'])
    const heightLength = properties.getIn(['depth', 'length'])
    const halfWidth = widthLength / 2;
    const halfHeight = heightLength / 2;
    const halfStrokeWidth = STANDARD_RESIZE_SHAPE.width / 2;
    const halfStrokeHeight = STANDARD_RESIZE_SHAPE.height / 2;
    let result = {
        leftBottomCorner: {
            name: RESIZING_POSITION.BOTTOM_LEFT,
            x: -halfWidth - halfStrokeWidth,
            y: -halfHeight - halfStrokeHeight,
            cursor: POSITION_CURSOR.BOTTOM_LEFT,
        },
        leftTopCorner: {
            name: RESIZING_POSITION.TOP_LEFT,
            x: -halfWidth - halfStrokeWidth,
            y: halfHeight - halfStrokeHeight,
            cursor: POSITION_CURSOR.TOP_LEFT,
        },
        rightBottomCorner: {
            name: RESIZING_POSITION.BOTTOM_RIGHT,
            x: halfWidth - halfStrokeWidth,
            y: -halfHeight - halfStrokeHeight,
            cursor: POSITION_CURSOR.BOTTOM_RIGHT,
        },
        rightTopCorner: {
            name: RESIZING_POSITION.TOP_RIGHT,
            x: halfWidth - halfStrokeWidth,
            y: halfHeight - halfStrokeHeight,
            cursor: POSITION_CURSOR.TOP_RIGHT,
        },
        middleTop: {
            name: RESIZING_POSITION.TOP_MIDDLE,
            x: -halfStrokeWidth,
            y: halfHeight - halfStrokeHeight,
            cursor: POSITION_CURSOR.TOP_MIDDLE,
        },
        middleLeft: {
            name: RESIZING_POSITION.LEFT_MIDDLE,
            x: -halfWidth - halfStrokeWidth,
            y: -halfStrokeHeight,
            cursor: POSITION_CURSOR.LEFT_MIDDLE,
        },
        middleRight: {
            name: RESIZING_POSITION.RIGHT_MIDDLE,
            x: halfWidth - halfStrokeWidth,
            y: -halfStrokeHeight,
            cursor: POSITION_CURSOR.RIGHT_MIDDLE,
        },
        middleBottom: {
            name: RESIZING_POSITION.BOTTOM_MIDDLE,
            x: -halfStrokeWidth,
            y: -halfHeight - halfStrokeHeight,
            cursor: POSITION_CURSOR.BOTTOM_MIDDLE,
        },
    };
    if (rotation === 90 || rotation === -90) {
        result = {
            ...result,
            middleTop: {
                ...result.middleTop,
                cursor: POSITION_CURSOR.LEFT_MIDDLE,
            },
            middleLeft: {
                ...result.middleLeft,
                cursor: POSITION_CURSOR.TOP_MIDDLE,
            },
            middleRight: {
                ...result.middleRight,
                cursor: POSITION_CURSOR.TOP_MIDDLE,
            },
            middleBottom: {
                ...result.middleBottom,
                cursor: POSITION_CURSOR.LEFT_MIDDLE,
            },

            leftBottomCorner: {
                ...result.leftBottomCorner,
                cursor: POSITION_CURSOR.BOTTOM_RIGHT,
            },
            leftTopCorner: {
                ...result.leftTopCorner,
                cursor: POSITION_CURSOR.BOTTOM_LEFT,
            },
            rightBottomCorner: {
                ...result.rightBottomCorner,
                cursor: POSITION_CURSOR.TOP_RIGHT,
            },
            rightTopCorner: {
                ...result.rightTopCorner,
                cursor: POSITION_CURSOR.TOP_LEFT,
            },
        }
    }
    return {
        ...result,

    };
}

function renderSquare({ x, y, cursor, name, text }, { item, layer }) {
    let angle = item.rotation + 90;

    let textRotation = 0;
    if (Math.sin(angle * Math.PI / 180) < 0) {
        textRotation = 180;
    }
    const onMouseDown = (e) => {
        e.preventDefault();
        // e.stopPropagation()
    };
    const onMouseUp = (e) => {
        e.preventDefault();
        // e.stopPropagation()
    };

    return (
        <g
            data-element-root
            data-prototype={item.prototype}
            data-id={item.id}
            data-selected={item.selected}
            data-layer={layer.id}
            data-part={"resizing-anchor"}
            data-resizing-position={name}
        >
            <rect
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                x={x}
                y={y}
                width={STANDARD_RESIZE_SHAPE.width}
                height={STANDARD_RESIZE_SHAPE.height}
                style={getResizeSquareStyle(cursor)}
            ></rect>
            {/* <text x={x} y={y} fill="black"
                transform={`translate(${STANDARD_RESIZE_SHAPE.width / 2}, ${STANDARD_RESIZE_SHAPE.height / 2 - 20}) scale(1,-1) rotate(${textRotation})`}
                style={{ textAnchor: 'middle', fontSize: '40px' }}>
                {text}
            </text> */}
        </g>
    );
}

function renderResizeSquares(data) {
    const { enableResizing } = getResizingDetail(data);
    if (!enableResizing) return null;
    const {
        leftBottomCorner,
        leftTopCorner,
        rightBottomCorner,
        rightTopCorner,
        middleTop,
        middleLeft,
        middleRight,
        middleBottom,
    } = getResizeSquaresConfiguration(data);
    return (
        <React.Fragment>
            {renderSquare(leftBottomCorner, data)}
            {renderSquare(leftTopCorner, data)}
            {renderSquare(rightBottomCorner, data)}
            {renderSquare(rightTopCorner, data)}
            {renderSquare(middleTop, data)}
            {renderSquare(middleLeft, data)}
            {renderSquare(middleRight, data)}
            {renderSquare(middleBottom, data)}
        </React.Fragment>
    );
}

function renderResizingContainer(data) {
    const { enableResizing, widthLength, heightLength } = getResizingDetail(data);
    if (enableResizing === true) {
        return <rect
            x={-widthLength / 2}
            y={-heightLength / 2}
            width={widthLength}
            height={heightLength}
            fill="none"
            stroke={STYLE_CIRCLE.fill}
            strokeWidth="1"
            style={{
                ...STYLE_CIRCLE2,
                cursor: "default",
            }}
            rx="5"
            ry="5"
        ></rect>

    }
    return null

}

export function renderResizingBorder(item, layer) {
    return <React.Fragment>
        {renderResizingContainer({ item, layer })}
        {renderResizeSquares({ item, layer })}
    </React.Fragment>
}

export function renderRotatingBorder(item, layer) {
    return (
        <g
            data-element-root
            data-prototype={item.prototype}
            data-id={item.id}
            data-selected={item.selected}
            data-layer={layer.id}
            data-part={"rotation-anchor"}
        >
            <circle cx="0" cy="150" r="10" style={STYLE_CIRCLE} />
            <circle cx="0" cy="0" r="150" style={STYLE_CIRCLE2} />
        </g>
    );
}

export function calculateNewCenterPoint(A, C) {
    const x = (A.x + C.x) / 2
    const y = (A.y + C.y) / 2
    return {
        x,
        y
    }
}

export function toRadian(degree) {
    return degree * (Math.PI / 180)
}


function calculateNewRotatedPoint(originX, originY, centerX, centerY, radian) {
    const actualX = originX - centerX
    const actualY = originY - centerY
    const rotatedX = (actualX * Math.cos(radian)) - (actualY * Math.sin(radian));
    const rotatedY = (actualX * Math.sin(radian)) + (actualY * Math.cos(radian));
    return {
        x: (rotatedX + centerX),
        y: (rotatedY + centerY)
    }
}

export function createVerticesResizing(widthLength, heightLength, center, inputDegree, enableResizing = true) {
    if (!enableResizing) {
        return []
    }
    const radian = toRadian(inputDegree);
    const { A, B, C, D } = {
        A: {
            x: center.x - (widthLength / 2),
            y: center.y - (heightLength / 2),
        },
        B: {
            x: center.x - (widthLength / 2),
            y: center.y + (heightLength / 2),
        },
        C: {
            x: center.x + (widthLength / 2),
            y: center.y + (heightLength / 2),
        },
        D: {
            x: center.x + (widthLength / 2),
            y: center.y - (heightLength / 2),
        }
    }
    const result = {
        A: calculateNewRotatedPoint(A.x, A.y, center.x, center.y, radian),
        B: calculateNewRotatedPoint(B.x, B.y, center.x, center.y, radian),
        C: calculateNewRotatedPoint(C.x, C.y, center.x, center.y, radian),
        D: calculateNewRotatedPoint(D.x, D.y, center.x, center.y, radian),
    }
    return {
        ...result,
        CENTER: calculateNewCenterPoint(result.A, result.C)
    }
}

export function createVerticesForResizingRect(item, inputDegree = 0) {
    const { center, widthLength, heightLength, enableResizing } = getResizingDetail({ item })
    return createVerticesResizing(widthLength, heightLength, center, inputDegree, enableResizing)
}




export function calculateResizingData(state, x,y) {

    let newWidth = 0;
    let newDepth = 0;
    let newX = 0
    let newY = 0

    
    let { resizingSupport, scene, itemSupport } = state;
    let layerID = resizingSupport.get('layerID');
    let itemID = resizingSupport.get('itemID');
    let item = scene.getIn(['layers', layerID, 'items', itemID]);
    const lastPositionX = item.get('x')
    const lastPositionY = item.get('y')
    let lastX = resizingSupport.get('lastX');
    let lastY = resizingSupport.get('lastY');
    let difX = x - lastX
    let difY = y - lastY
    let rotation = item.get('rotation')
    let properties = item.get('properties')
    let width = properties.get('width')
    let depth = properties.get('depth')
    let widthLength = width.get('length')
    let depthLength = depth.get('length')
    let position = resizingSupport.get('position');

    const getDelta = (inputDeltaWidth, inputDeltaDepth, angleName = 'A') => {
        const { [itemID]: {
            vertices: { [angleName]: oldVertex }
        } } = itemSupport.toJS()
        const { [angleName]: newVertex } = createVerticesResizing(inputDeltaWidth, inputDeltaDepth, {
            x: lastPositionX,
            y: lastPositionY
        }, rotation, true)
        const deltaX = oldVertex.x - newVertex.x
        const deltaY = oldVertex.y - newVertex.y
        return {
            x: deltaX,
            y: deltaY
        }
    }
    
    {
        switch (position) {
            case RESIZING_POSITION.TOP_MIDDLE:
                newWidth = widthLength
                newDepth = depthLength + difY
                newX = lastPositionX
                newY = lastPositionY + (difY / 2)
                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth)
                    if (rotation === 90) {
                        newWidth = widthLength
                        newDepth = depthLength + (difX * -1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY
                    } else if (rotation === -180) {
                        newWidth = widthLength
                        newDepth = depthLength + (difY * -1)
                        newX = lastPositionX
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -90) {
                        newWidth = widthLength
                        newDepth = depthLength + (difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            newDepth = depthLength + (difY * -1)
                            newX = lastPositionX + (deltaX * -1)
                            newY = lastPositionY + (deltaY * -1)
                        } else {
                            newX = lastPositionX + deltaX
                            newY = lastPositionY + deltaY
                        }
                    }
                }

                break;

            case RESIZING_POSITION.LEFT_MIDDLE:
                newWidth = widthLength + (difX * -1)
                newDepth = depthLength
                newX = lastPositionX + (difX / 2)
                newY = lastPositionY
                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth)
                    if (rotation === 90) {
                        newWidth = widthLength + (difY * -1)
                        newDepth = depthLength
                        newX = lastPositionX
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -180) {
                        newWidth = widthLength + (difX * 1)
                        newDepth = depthLength
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY
                    } else if (rotation === -90) {
                        newWidth = widthLength + (difY * 1)
                        newDepth = depthLength
                        newX = lastPositionX
                        newY = lastPositionY + (difY / 2)
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            newWidth = widthLength + (difX * 1)
                            newX = lastPositionX + (deltaX * 1)
                            newY = lastPositionY + (deltaY * 1)
                        } else {
                            newX = lastPositionX + -deltaX
                            newY = lastPositionY + -deltaY
                        }
                    }
                }
                break;

            case RESIZING_POSITION.BOTTOM_MIDDLE:
                newWidth = widthLength
                newDepth = depthLength + (difY * -1)
                newX = lastPositionX
                newY = lastPositionY + (difY / 2)

                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth)
                    if (rotation === 90) {
                        newWidth = widthLength
                        newDepth = depthLength + (difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY
                    } else if (rotation === -180) {
                        newWidth = widthLength
                        newDepth = depthLength + (difY * 1)
                        newX = lastPositionX
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -90) {
                        newWidth = widthLength
                        newDepth = depthLength + (difX * -1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            newWidth = widthLength
                            newDepth = depthLength + (difY * 1)
                            newX = lastPositionX + (deltaX * 1)
                            newY = lastPositionY + (deltaY * 1)
                        } else {
                            newX = lastPositionX + -deltaX
                            newY = lastPositionY + -deltaY
                        }
                    }
                }
                break;

            case RESIZING_POSITION.RIGHT_MIDDLE:
                newWidth = widthLength + (difX * 1)
                newDepth = depthLength
                newX = lastPositionX + (difX / 2)
                newY = lastPositionY
                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth)
                    if (rotation === 90) {
                        newWidth = widthLength + (difY * 1)
                        newDepth = depthLength
                        newX = lastPositionX
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -180) {
                        newWidth = widthLength + (difX * -1)
                        newDepth = depthLength
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY
                    } else if (rotation === -90) {
                        newWidth = widthLength + (-difY * 1)
                        newDepth = depthLength
                        newX = lastPositionX
                        newY = lastPositionY + (difY / 2)
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            newWidth = widthLength + (-difX * 1)
                            newX = lastPositionX + (deltaX * -1)
                            newY = lastPositionY + (deltaY * -1)
                        } else {
                            newX = lastPositionX + deltaX
                            newY = lastPositionY + deltaY
                        }
                    }
                }
                break;

            case RESIZING_POSITION.TOP_LEFT:
                newWidth = widthLength + (difX * -1)
                newDepth = depthLength + difY
                newX = lastPositionX + (difX / 2)
                newY = lastPositionY + (difY / 2)
                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth, 'B')
                    if (rotation === 90) {
                        newWidth = widthLength + (difY * -1)
                        newDepth = depthLength + (difX * -1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -180) {
                        newWidth = widthLength + (difX * 1)
                        newDepth = depthLength + (difY * -1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -90) {
                        newWidth = widthLength + (difY * 1)
                        newDepth = depthLength + (difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            // @victor note (-180 to -270)
                            newWidth = widthLength + (difY * 1)
                            newDepth = depthLength + (difX * 1)
                            newX = lastPositionX + (deltaY * 1)
                            newY = lastPositionY + (-deltaX * 1)
                        } else {
                            newWidth = widthLength + (-difX * 1)
                            newDepth = depthLength + (difY * 1)
                            newX = lastPositionX + (-deltaX * 1)
                            newY = lastPositionY + (-deltaY * 1)
                        }
                    }
                }
                break;

            case RESIZING_POSITION.BOTTOM_LEFT:
                newWidth = widthLength + (difX * -1)
                newDepth = depthLength + (difY * -1)
                newX = lastPositionX + (difX / 2)
                newY = lastPositionY + (difY / 2)
                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth)
                    if (rotation === 90) {
                        newWidth = widthLength + (difY * -1)
                        newDepth = depthLength + (difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -180) {
                        newWidth = widthLength + (difX * 1)
                        newDepth = depthLength + (difY * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -90) {
                        newWidth = widthLength + (difY * 1)
                        newDepth = depthLength + (-difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            newWidth = widthLength + difX
                            newDepth = depthLength + difY
                            newX = lastPositionX + deltaX
                            newY = lastPositionY + deltaY
                        } else {
                            newX = lastPositionX + -deltaX
                            newY = lastPositionY + -deltaY
                        }
                    }
                }
                break;

            case RESIZING_POSITION.TOP_RIGHT:
                newWidth = widthLength + (difX * 1)
                newDepth = depthLength + (difY * 1)
                newX = lastPositionX + (difX / 2)
                newY = lastPositionY + (difY / 2)
                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth)
                    if (rotation === 90) {
                        newWidth = widthLength + (difY * 1)
                        newDepth = depthLength + (difX * -1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -180) {
                        newWidth = widthLength + (difX * -1)
                        newDepth = depthLength + (difY * -1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -90) {
                        newWidth = widthLength + (difY * -1)
                        newDepth = depthLength + (difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            newWidth = widthLength + (difX * -1)
                            newDepth = depthLength + (-difY * 1)
                            newX = lastPositionX + (-deltaX)
                            newY = lastPositionY + (-deltaY)
                        } else {
                            newWidth = widthLength + (difX * 1)
                            newDepth = depthLength + (difY * 1)
                            newX = lastPositionX + (deltaX * 1)
                            newY = lastPositionY + (deltaY * 1)
                        }
                    }
                }
                break;

            case RESIZING_POSITION.BOTTOM_RIGHT:
                newWidth = widthLength + (difX * 1)
                newDepth = depthLength + (difY * -1)
                newX = lastPositionX + (difX / 2)
                newY = lastPositionY + (difY / 2)
                if (rotation !== 0) {
                    const { x: deltaX, y: deltaY } = getDelta(newWidth, newDepth, 'B')
                    if (rotation === 90) {
                        newWidth = widthLength + (difY * 1)
                        newDepth = depthLength + (difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -180) {
                        newWidth = widthLength + (-difX * 1)
                        newDepth = depthLength + (difY * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else if (rotation === -90) {
                        newWidth = widthLength + (-difY * 1)
                        newDepth = depthLength + (-difX * 1)
                        newX = lastPositionX + (difX / 2)
                        newY = lastPositionY + (difY / 2)
                    } else {
                        if (rotation < 0 && rotation < -90 && rotation > -270) {
                            newWidth = widthLength + -difX
                            newDepth = depthLength + difY
                            newX = lastPositionX + -deltaX
                            newY = lastPositionY + -deltaY
                        } else {
                            newWidth = widthLength + (difX * 1)
                            newDepth = depthLength + (difY * -1)
                            newX = lastPositionX + deltaX
                            newY = lastPositionY + deltaY
                        }
                    }
                }
                break;

            default:
                newWidth = widthLength
                newDepth = depthLength
                newX = lastPositionX
                newY = lastPositionY
                break;
        }
    }
    return {
        newWidth,
        newDepth,
        newX,
        newY,
    }
}