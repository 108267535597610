import React, { Component, useState } from "react";
import PropTypes from "prop-types";
const containerStyle = {
  color: "white",
  margin: "1rem 0 0 1rem",
};

class PanelWardrobe extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const turnOn = () => {
      this.context.wardrobeActions.setWardrobeMode(this.props.state, true);
    };
    const turnOff = () => {
      this.context.wardrobeActions.setWardrobeMode(this.props.state, false);
    };
    const status = this.props.state.wardrobe.status;
    return (
      <div style={containerStyle}>
        {status === true && (
          <button onClick={turnOff}>Turn off Built-in Wardrobe Mode</button>
        )}
        {status === false && (
          <button onClick={turnOn}>Turn on Built-in Wardrobe Mode</button>
        )}
      </div>
    );
  }
}

PanelWardrobe.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  groupsActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  wardrobeActions: PropTypes.object.isRequired,
};

PanelWardrobe.propTypes = {
  state: PropTypes.object.isRequired,
};

export default PanelWardrobe;
