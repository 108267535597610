import { fromJS } from 'immutable';

class Wardrobe{
  static setWardrobeMode( state, status ){
    state = state.setIn(['wardrobe', 'status'], status);
    return {updatedState: state};
  }

}

export { Wardrobe as default };
