import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import Panel from "./panel";
import { mergeRoom, roomGenerator } from "../../utils/rooms";

const containerStyle = {
  color: "white",
  margin: "1rem 0 1rem 1rem"  
};



class PanelRoomDrawAssistant extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    try {
      let { state } = this.props;
      let { translator } = this.context;
      const mergeRoomHandler = () => {
        mergeRoom(this.context);
      }
      return (
        <Panel name={translator.t('RoomDrawAssistant')} opened={true}>
          <div style={containerStyle}>
            <button onClick={mergeRoomHandler}>Merge room</button>
          </div>  
        </Panel>
      
      );
    } catch (error) {
      console.log(error);
      return null;
    }
    
  }
}

PanelRoomDrawAssistant.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  groupsActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  wardrobeActions: PropTypes.object.isRequired,
};

PanelRoomDrawAssistant.propTypes = {
  state: PropTypes.object.isRequired,
};

export default PanelRoomDrawAssistant;
