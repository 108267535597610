import { 
    getStateReplica,
    getCurrentLayer,
    getCurrentLayerId,
    getCurrentLines,
    getCurrentScene,
    getCurrentVertices,
    getLineById,
    getCurrentGroups
} from "./stateReplica";
import {Seq } from 'immutable';

import { MODE_DRAGGING_GROUP } from "../constants";

export const drawLineGenerator = (context, SNAP_MASK, layerId) => {
    return (x1, y1, x2, y2) => {
        context.linesActions.beginDrawingLine(layerId, x1, y1, SNAP_MASK);
        context.linesActions.endDrawingLine(x2, y2, SNAP_MASK);
    }
}

export const drawShapeGenerator = (context, SNAP_MASK, layerId) => {
    return (...args) => {
        context.projectActions.disableSnapping();
        context.linesActions.selectToolDrawingLine('wall');
        const draw = drawLineGenerator(context, SNAP_MASK, layerId);
        const inputs = args;
        let inputAxises = [];
        if (inputs.length > 0) {
            for (let i = 0; i < inputs.length; i++) {
                const currentAxis = inputs[i];
                const nextAxis = inputs[i + 1];
                if (nextAxis === undefined) {
                    break;
                }
                const x1 = currentAxis[0]
                const y1 = currentAxis[1];
                const x2 = nextAxis[0];
                const y2 = nextAxis[1];
                draw(x1, y1, x2, y2);
                inputAxises.push({
                    A: { x: x1, y: y1},
                    B: { x: x2, y: y2}
                });
            }
            
        }
        setTimeout(() => {

            const createdLines = getTheCreatedLines(inputAxises);
            groupTheLines(context, layerId, createdLines);
            setTimeout(() => {
                // draw(0, 0, 0, 0);
                setTimeout(() => {
                    // context.projectActions.rollback();
                    // setTimeout(() => {
                    //     const currentGroup = getCurrentGroups();
                    //     console.log('currentGroup', currentGroup.toJS());
                    //     // const thisGroupId = '';
                    //     // context.groupsActions.selectGroup(thisGroupId);
                    // });
                });
                
            })
        });
       
    }
}

const removeItem = (context, layerId, itemId) => {
    if (itemId === '') return;
    setTimeout(() => {
        context.itemsActions.selectItem(layerId, itemId);
        context.projectActions.remove();
    })
}

export const roomGenerator = (context) => {
    return (type) => {
        try {
            console.log('type', type);
            const state = getStateReplica();
            const SNAP_MASK = state.snapMask;
            const { scene } = state;
            const layerId = state.getIn(['scene', 'selectedLayer']);
            const layerRenderer = layer => Seq()
            .concat(layer.lines, layer.holes, layer.areas, layer.items)
            .filter(element => element.selected)
            .map(element => {
                return {
                    element: element.toJS(), layer: layer.toJS()
                }
            })
            .valueSeq();
            const selectedData = scene.layers.valueSeq().map(layerRenderer)
            const selected = selectedData.size > 0 ? selectedData.toJSON() : undefined;
            if (!selected) return null;
            let axis = {
                x: 0,
                y: 0,
            }
            let info = {
                id: '',
                height: 100,
                width: 100,
                depth: 100,
            }
            if (selected.length === 1 && selected[0].length === 0) {                
                const defaultElement = state.catalog.toJS().elements[type];
                axis = state.mouse.toJS();
                info = {
                    id: '',
                    height: defaultElement.properties.height.defaultValue.length,
                    width: defaultElement.properties.width.defaultValue.length,
                    depth: defaultElement.properties.depth.defaultValue.length,
                }
            } else {
                const selectedItem = selected[0][0];
                const { element, layer } = selectedItem;
                if (!element || !layer) return null;
                // console.log('element', element.type);
                // console.log('layer', layer);
                // if (element.prototype !== "items") return null;
                if (element.x && element.y) {
                    axis = {
                        x: element.x,
                        y: element.y
                    }
                }
                try {
                    info = {
                        id: element.id,
                        height: element.properties.height.length,
                        width: element.properties.width.length,
                        depth: element.properties.depth.length,
                    }
                } catch (error) {
                    // console.log('error', error);
                    const defaultElement = state.catalog.toJS().elements[type];
                    info = {
                        id: '',
                        height: defaultElement.properties.height.defaultValue.length,
                        width: defaultElement.properties.width.defaultValue.length,
                        depth: defaultElement.properties.depth.defaultValue.length,
                    }
                }
                
            }
            // console.log('axis', axis);
            // console.log('info', info);
            context.projectActions.unselectAll();

            const drawShape = drawShapeGenerator(context, SNAP_MASK, layerId);

            if (type === 'rectangular') {
                const { x, y} = axis;
                const { width, depth, height, id: itemId } = info;
                const pointA = {
                    x: x - width / 2,
                    y: y + depth / 2
                };
                const pointB = {
                    x: x + width / 2,
                    y: y + depth / 2
                };
                const pointC = {
                    x: x + width / 2,
                    y: y - depth / 2
                };
                const pointD = {
                    x: x - width / 2,
                    y: y - depth / 2
                };
                let drawAxises = [
                    [pointA.x, pointA.y],
                    [pointB.x, pointB.y],
                    [pointC.x, pointC.y],
                    [pointD.x, pointD.y],
                    [pointA.x, pointA.y],
                    [pointA.x, pointA.y],
                ];
                drawShape(...drawAxises);
                removeItem(context, layerId, itemId);
            }

            if (type === 't-shape') {
                const { x, y} = axis;
                const { width, depth, height, id: itemId } = info;
                const pointA = {
                    x: x - width / 2,
                    y: y + depth / 2
                };
                const pointB = {
                    x: x + width / 2,
                    y: y + depth / 2
                };
                const pointC1 = {
                    x: x + width / 2,
                    y: y + depth / 6
                };
                const pointC2 = {
                    x: x + width / 3.5,
                    y: y + depth / 6
                };
                const pointC3 = {
                    x: x + width / 3.5,
                    y: y - depth / 2
                };
                const pointD1 = {
                    x: x - width / 3.5,
                    y: y - depth / 2
                };
                const pointD2 = {
                    x: x - width / 3.5,
                    y: y + depth / 6
                };
                const pointD3 = {
                    x: x - width / 2,
                    y: y + depth / 6,
                };          
                let drawAxises = [
                    [pointA.x, pointA.y],
                    [pointB.x, pointB.y],
                    [pointC1.x, pointC1.y],
                    [pointC2.x, pointC2.y],
                    [pointC3.x, pointC3.y],
                    [pointD1.x, pointD1.y],
                    [pointD2.x, pointD2.y],
                    [pointD3.x, pointD3.y],
                    [pointA.x, pointA.y],
                    [pointA.x, pointA.y],
                ];
                drawShape(...drawAxises);
                removeItem(context, layerId, itemId);
            }

            const isLeftSideOfLShape = false;
            // left-side
            if (type === 'l-shape' && isLeftSideOfLShape === true) { 
                const { x, y} = axis;
                const { width, depth, height, id: itemId } = info;
                const pointA = {
                    x: x - width / 2,
                    y: y + depth / 2
                };
                const pointB = {
                    x: x + width / 2,
                    y: y + depth / 2
                };
                const pointC1 = {
                    x: x + width / 2,
                    y: y - (depth / 6)
                };
                const pointC2 = {
                    x: x - (width / 6),
                    y: y - (depth / 6)
                };
                const pointC3 = {
                    x: x - (width / 6),
                    y: y - depth / 2
                };
                const pointD = {
                    x: x - width / 2,
                    y: y - depth / 2
                };                 
                let drawAxises = [
                    [pointA.x, pointA.y],
                    [pointB.x, pointB.y],
                    [pointC1.x, pointC1.y],
                    [pointC2.x, pointC2.y],
                    [pointC3.x, pointC3.y],
                    [pointD.x, pointD.y],
                    [pointA.x, pointA.y],
                    [pointA.x, pointA.y],
                ];
                drawShape(...drawAxises);
                removeItem(context, layerId, itemId);
            }

            // right-side
            if (type === 'l-shape') { 
                const { x, y} = axis;
                const { width, depth, height, id: itemId } = info;
                const pointA = {
                    x: x - width / 2,
                    y: y + depth / 2
                };
                const pointB = {
                    x: x + width / 2,
                    y: y + depth / 2
                };
                const pointC = {
                    x: x + width / 2,
                    y: y - depth / 2
                }
                const pointD1 = {
                    x: x + (width / 6),
                    y: y - depth / 2
                };
                const pointD2 = {
                    x: x + (width / 6),
                    y: y - (depth / 6)
                };
                const pointD3 = {
                    x: x - width / 2,
                    y: y - (depth / 6)
                };
                let drawAxises = [
                    [pointA.x, pointA.y],
                    [pointB.x, pointB.y],
                    [pointC.x, pointC.y],
                    [pointD1.x, pointD1.y],
                    [pointD2.x, pointD2.y],
                    [pointD3.x, pointD3.y],
                    [pointA.x, pointA.y],
                    [pointA.x, pointA.y],
                ];
                drawShape(...drawAxises);
                removeItem(context, layerId, itemId);
            }

            if (type === 'c-shape') { 
                const { x, y} = axis;
                const { width, depth, height, id: itemId } = info;
                const pointA = {
                    x: x - width / 2,
                    y: y + depth / 2
                };
                const pointB = {
                    x: x + width / 2,
                    y: y + depth / 2
                };
                const pointC1 = {
                    x: x + width / 2,
                    y: y - depth / 2
                }
                const pointC2 = {
                    x: x + (width / 6),
                    y: y - depth / 2
                };
                const pointC3 = {
                    x: x + (width / 6),
                    y: y - (depth / 6)
                };
                const pointD1 = {
                    x: x - (width / 6),
                    y: y - (depth / 6)
                };
                const pointD2 = {
                    x: x - (width / 6),
                    y: y - depth / 2
                };
                const pointD3 = {
                    x: x - width / 2,
                    y: y - depth / 2
                };        
                let drawAxises = [
                    [pointA.x, pointA.y],
                    [pointB.x, pointB.y],
                    [pointC1.x, pointC1.y],
                    [pointC2.x, pointC2.y],
                    [pointC3.x, pointC3.y],
                    [pointD1.x, pointD1.y],
                    [pointD2.x, pointD2.y],
                    [pointD3.x, pointD3.y],
                    [pointA.x, pointA.y],
                    [pointA.x, pointA.y],
                ];
                drawShape(...drawAxises);
                removeItem(context, layerId, itemId);
            }

        } catch (error) {
            console.log('error on Room Utils', error);
        }
        
       
    }
}

export const getTheCreatedLines = (inputAxises) => {
    const currentLayer = getCurrentLayer();
    const allLines = currentLayer.get('lines').toArray().map(l => l.toJS());
    const allVertices = currentLayer.get('vertices').toArray().map(v => v.toJS());
    // console.log('inputAxises', inputAxises);
    // console.log('allLines', allLines);
    // console.log('allVertices', allVertices);
    let listOfVertices = new Set();
    for (let axis of inputAxises) {
        const foundA = allVertices.find(v => v.x === axis.A.x && v.y === axis.A.y);
        const foundB = allVertices.find(v => v.x === axis.B.x && v.y === axis.B.y);
        if (foundA && foundB) {
            listOfVertices.add(foundA.id);
            listOfVertices.add(foundB.id);
        }
    }
    const listVertices = Array.from(listOfVertices);
    // console.log('listVertices', listVertices);

    let createdLines = [];
    for (let line of allLines) {
        // @todo: should improve this algorithm.
        const hasCreatedVertices = line.vertices.find(v => listVertices.includes(v));
        if (hasCreatedVertices) {
            createdLines.push(line);
        }
    }
    // console.log('createdLines', createdLines);

    return createdLines;
}

export const groupTheLines = (context, layerId, inputLines) => {
    const currentGroup = getCurrentGroups().toArray().map(g => g.toJS().id);
    context.groupsActions.addGroup();
    const updatedGroup = getCurrentGroups().toArray().map(g => g.toJS().id);
    let diff = updatedGroup.filter(g => !currentGroup.includes(g));
    if (diff.length > 0) {
        const newCreatedGroup = getCurrentGroups().get(diff[0]).toJS();
        // console.log("new created group", newCreatedGroup);
        for (let line of inputLines) {
            // console.log('line', line);
            const thisLine = getLineById(line.id);
            if (thisLine) {
                const newLine = thisLine.toJS();
                context.groupsActions.addToGroup(newCreatedGroup.id, layerId, "lines", newLine.id)
            }
        }
        
        context.groupsActions.selectGroup(newCreatedGroup.id);
        context.projectActions.setMode(MODE_DRAGGING_GROUP);

    }
}

export const getGroupFromArea = (getClosest) => {
    // use the selected area to find the corresponding group
    const currentLayer = getCurrentLayer();
    const layerId = currentLayer.get('id');
    const selectedArea = currentLayer.get('areas').toArray().map(a => a.toJS()).find(a => a.selected === true);
    // console.log('selectedArea', selectedArea);
    if (!selectedArea) return undefined;
    const currentVertices = getCurrentVertices();
    const selectedVertices = currentVertices.toArray().map(s => s.toJS())
    .filter(v => selectedArea.vertices.includes(v.id))
    // remove the one with x,y is null
    .filter(v => v.x !== null && v.y !== null);
    ;
    // console.log('selectedVertices', selectedVertices);
    const selectedLines = selectedVertices.reduce((origin, current) => {
      if (!origin.length) {
        return [
          ...origin.lines
        ]
      } else {
        return [
          ...origin,
          ...current.lines,
        ]
      }
    });
    // console.log('selected lines', selectedLines);
    const currentGroups = getCurrentGroups().toArray().map(g => g.toJS());
    const selectedGroups = currentGroups.map(g => {
      try {
        return {
          id: g.id,
          lines: g.elements[layerId].lines
        }  
      } catch (error) {
        return {
          id: g.id,
          lines: []
        }
      }
      
    })
    // remove the one without any line
    .filter(g => g.lines.length > 0)
    ;
    // console.log('selected groups', selectedGroups);

    const compareGroups = selectedGroups.map(g => {
      const sum = g.lines.filter(l => selectedLines.includes(l)).length;
      return {
        ...g,
        sum,
        matched: g.lines.length === sum
      }
    });
    // console.log('compareGroups', compareGroups);
    const newSelectedGroup = compareGroups.find(g => g.matched === true);
    if (newSelectedGroup) {
      // console.log('new selected group', newSelectedGroup);
      return newSelectedGroup;
    } else {
        if (getClosest === true) {
            // console.log('Can not found group with area');
            const theClosestGroup = compareGroups.sort((a,b) => b.sum - a.sum)[0];
            // console.log('closet group', theClosestGroup);
            if (theClosestGroup) {
                return theClosestGroup
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
      
    }
}

const delay= (miliseconds) => new Promise((resolve) => setTimeout(() => resolve(), miliseconds))

export const mergeRoom = (context) => {
    const currentGroups = getCurrentGroups();
    context.projectActions.enableSnapping();
    let allTheGroups = currentGroups.toArray().map(g => g.toJS());
    allTheGroups.forEach(g => {
        context.groupsActions.removeGroup(g.id);
    });

    const currentLines = getCurrentLines();
    const currentVertices = getCurrentVertices();
    const currentLayerId = getCurrentLayerId();
    // const currentSnapMask = getCurrentSnapMask();
    if (currentLines && currentVertices && currentLayerId) {
        const lines = currentLines.toArray().map(l => l.toJS());
        const vertices = currentVertices.toArray().map(v => v.toJS());
        const merging = () => {
            for (let line of lines) {
                const lineVertices = vertices.filter(v => line.vertices.includes(v.id));
                if (lineVertices.length && lineVertices.length === 2) {
                    const vertex01 = lineVertices[0];
                    const { x, y } = vertex01;
                    context.linesActions.beginDraggingLine(currentLayerId, line.id, x, y);
                    context.linesActions.endDraggingLine(x, y);
                }
            }
        }
        merging();

        delay(50).then(() => {
            merging();
        });

        delay(100).then(() => {
            context.areaActions.detectAndUpdateArea(currentLayerId);
        });

        delay(200).then(() => {
            merging();
        });

        delay(400).then(() => {
            context.areaActions.detectAndUpdateArea(currentLayerId);
        });

        console.log('apply merging for all lines');
    }
}