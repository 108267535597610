export const dispatchCustomEvent = (eventName, detail) => document.dispatchEvent(new CustomEvent(eventName, { detail }));

export const addListener = (eventName, handler) => document.addEventListener(eventName, handler);

export const removeListener = (eventName, handler) => document.removeEventListener(eventName, handler);

const events = {
    dispatchCustomEvent,
    addListener,
    removeListener,
};

export default events;