import {SELECT_AREA, DETECT_AND_UPDATE_AREA} from '../constants';

export function selectArea(layerID, areaID) {
  return {
    type: SELECT_AREA,
    layerID,
    areaID
  }
}

export function detectAndUpdateArea(layerID) {
  return {
    type: DETECT_AND_UPDATE_AREA,
    layerID,
  }
}
