let state;
export const setStateReplica = (newState) => {
    state = newState
};

export const getStateReplica = () => state;


export const getCurrentScene = () => {
    const currentState = getStateReplica();
    return currentState.get('scene');
}

export const getCurrentLayer = () => {
    const currentState = getStateReplica();
    const selectedLayer = currentState.getIn(['scene', 'selectedLayer']);
    const thisLayer = currentState.get('scene').get('layers').get(selectedLayer);
    return thisLayer;
}

export const getCurrentLayerId = () => {
    const currentLayer = getCurrentLayer();
    if (currentLayer) {
        return currentLayer.get('id');
    }
    return undefined;
}

export const getCurrentSnapMask = () => {
    const currentState = getStateReplica();
    return currentState.get('snapMask');
}

export const getCurrentLines = () => {
    const currentLayer = getCurrentLayer();
    return currentLayer.get('lines');
}

export const getCurrentVertices = () => {
    const currentLayer = getCurrentLayer();
    return currentLayer.get('vertices');
}

export const getLineById = (lineId) => {
    const thisLayer = getCurrentLayer();
    const thisLine = thisLayer.get('lines').get(lineId);
    return thisLine;
}

export const getCurrentGroups = () => {
    const thisScene = getCurrentScene();
    return thisScene.get('groups');
}