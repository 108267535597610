import { Wardrobe } from '../class/export';
import { SET_WARDROBE_MODE } from '../constants';

export default function (state, action) {
  switch (action.type) {    
    case SET_WARDROBE_MODE:
      return Wardrobe.setWardrobeMode(state, action.status).updatedState;
    default:
      return state;
  }
}
