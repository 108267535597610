import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import If from "../../utils/react-if";
import { renderResizingBorder, renderRotatingBorder } from "../../utils/resizing";

export default function Item({ layer, item, scene, catalog }) {
  let { x, y, rotation } = item;
  let renderedItem = catalog.getElement(item.type).render2D(item, layer, scene);
  return (
    <g
      data-element-root
      data-prototype={item.prototype}
      data-id={item.id}
      data-selected={item.selected}
      data-layer={layer.id}
      style={item.selected ? { cursor: "move" } : {}}
      transform={`translate(${x},${y}) rotate(${rotation})`}
    >
      {renderedItem}
      <If condition={item.selected}>
        {renderRotatingBorder(item, layer)}                  
      </If>
      <If condition={item.selected}>
        {renderResizingBorder(item, layer)}          
      </If>
    </g>
  );
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
};
