import { Line } from '../class/export';


function isIntersectionInsideAStraightLine(startPoint, intersectionPoint, endPoint) {
    let left = ((intersectionPoint.x - startPoint.x) / (endPoint.x - startPoint.x))
    let right = ((intersectionPoint.y - startPoint.y) / (endPoint.y - startPoint.y))
    if (isNaN(left) || isNaN(right)) {
        return true
    }
    const isOnAStraightLine = left == right
    return isOnAStraightLine && left >= 0 && left <= 1
}

function checkCommonVertex(state, layerID, lineIDs) {
    try {
        let layer = state.getIn(['scene', 'layers', layerID])
        for (const lineID of lineIDs) {
            let line = layer.getIn(['lines', lineID])
            let vertices = line.get('vertices')
            let intersectionVertices = layer.get('vertices').filter(v => v.lines.size > 1).filter(v => {
                for (const thisVertex of vertices) {
                    if (v.lines.includes(lineID) && v.id === thisVertex) {
                        return true
                    }
                }
                return false
            })
            const intersectionVerticesArray = intersectionVertices.toArray()
            for (const intersectionVertex of intersectionVerticesArray) {
                const twoSideVertices = layer.get('vertices').filter(v => {
                    for (const intersectionLine of intersectionVertex.get('lines')) {
                        if (v.lines.includes(intersectionLine) && v.id !== intersectionVertex.get('id')) {
                            return true
                        }
                    }
                    return false
                })
                if (twoSideVertices.size >= 2) {
                    const twoSideVerticesArray = twoSideVertices.toArray()
                    const { x: Ax, y: Ay, id: Aid, lines: ALines } = twoSideVerticesArray[0].toJS()
                    const { x: Bx, y: By, id: Bid, lines: BLines } = twoSideVerticesArray[1].toJS()
                    const { x: Cx, y: Cy, id: Cid, lines: CLines } = intersectionVertex.toJS()
                    const startPoint = { x: Ax, y: Ay }
                    const endPoint = { x: Bx, y: By }
                    const intersectionPoint = { x: Cx, y: Cy }
                    const result = isIntersectionInsideAStraightLine(startPoint, intersectionPoint, endPoint)
                    if (result) {
                        const currentAutoVertexCollapseSupport = state.get('autoVertexCollapseSupport')
                        const newId = Aid + Bid + Cid
                        state = state.merge({
                            autoVertexCollapseSupport: currentAutoVertexCollapseSupport.merge({
                                [newId]: {
                                    id: newId,
                                    startPoint: {
                                        ...startPoint,
                                        id: Aid,
                                        lines: ALines,
                                    },
                                    intersectionPoint: {
                                        ...intersectionPoint,
                                        id: Cid,
                                        lines: CLines.length > 2 ? [] : CLines,
                                    },
                                    endPoint: {
                                        ...endPoint,
                                        id: Bid,
                                        lines: BLines,
                                    },
                                }
                            })
                        })
                    } else {
                        // console.log('no')
                    }
                } else {
                    // console.log('no side vertices', twoSideVertices.toJS())
                }
            }

        }

    } catch (error) {
        console.log('error', error)
    }
    return { updatedState: state };
}

function removeVertices(state, layerID) {
    const autoVertexCollapseSupport = state.get('autoVertexCollapseSupport')
    if (autoVertexCollapseSupport.size > 0) {
        const data = autoVertexCollapseSupport.toArray()
        for (const change of data) {
            const { startPoint, intersectionPoint, endPoint, id } = change.toJS()
            state = state.merge({
                autoVertexCollapseSupport: autoVertexCollapseSupport.delete(id)
            })
            state = removeVerticesAndCreateNewLine(state, layerID, startPoint, endPoint, intersectionPoint.lines).updatedState
        }
    }

    return { updatedState: state };
}

function removeVerticesAndCreateNewLine(state, layerID, startPoint, endPoint, commonLines) {
    const allowedLineType = 'wall'
    let removeLineIds = []
    for (const lineID of commonLines) {
        try {
            const line = state.getIn(['scene', 'layers', layerID, 'lines', lineID])
            if (line) {
                const lineType = line.type;
                const conditions = lineType === allowedLineType
                if (conditions) {
                    const vertices = line.get('vertices').toArray()
                    for (const vertex of vertices) {
                        if (isIntersectionInsideAStraightLine(startPoint, vertex, endPoint)) {
                            if (!removeLineIds.includes(lineID)) {
                                // console.log('remove line', lineID, vertices);
                                state = Line.remove(state, layerID, lineID).updatedState
                                removeLineIds.push(lineID)
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log('error', error)
        }

    }
    if (removeLineIds.length > 0) {
        // console.log('create new line', startPoint, endPoint);
        state = Line.create(state, layerID, allowedLineType, startPoint.x, startPoint.y, endPoint.x, endPoint.y).updatedState
    }

    return { updatedState: state };
}

export function autoVertexCollapse(state, layerID) {
    const allVertices = state.getIn(['scene', 'layers', layerID, 'vertices']).toArray();
    for (const currentVertex of allVertices) {
        const currentVertexId = currentVertex.toJS().id
        let checkLines = state.getIn(['scene', 'layers', layerID, 'vertices', currentVertexId, 'lines']);
        const linesArray = checkLines.toArray()
        state = checkCommonVertex(state, layerID, linesArray).updatedState
    }
    state = removeVertices(state, layerID).updatedState
    return { updatedState: state };
}