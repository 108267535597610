import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import If from '../../utils/react-if';
import * as sharedStyles from '../../shared-style';
import { MODE_DRAGGING_GROUP, MODE_IDLE } from '../../constants';
import events from '../../utils/events';

const cx = 0;
const cy = 0;
const radius = 5;

const STYLE_CIRCLE_BARY_CENTER = {
  fill: sharedStyles.MATERIAL_COLORS[500].orange,
  stroke: sharedStyles.MATERIAL_COLORS[500].orange,
  cursor: 'default'
};

const STYLE_CIRCLE = {
  fill: 'transparent', // sharedStyles.MATERIAL_COLORS[500].orange,
  stroke: 'transparent', // sharedStyles.MATERIAL_COLORS[500].orange,
  cursor: 'default'
};

const STYLE_CIRCLE_MOVE = {
  fill: sharedStyles.MATERIAL_COLORS[500].blue,
  stroke: sharedStyles.MATERIAL_COLORS[500].blue,
  cursor: "grab",
};

const STYLE_CIRCLE_REMOVE = {
  fill: sharedStyles.MATERIAL_COLORS[500].red,
  stroke: sharedStyles.MATERIAL_COLORS[500].red,
  cursor: "pointer",
};

const STYLE_CIRCLE_ADDED = {
  fill: sharedStyles.MATERIAL_COLORS[500].green,
  stroke: sharedStyles.MATERIAL_COLORS[500].green,
  cursor: "pointer",
};

const STYLE_CIRCLE_ADDED_DISABLE_SNAPPING = {
  fill: sharedStyles.MATERIAL_COLORS[500].amber,
  stroke: sharedStyles.MATERIAL_COLORS[500].amber,
  cursor: "pointer",
};


export default function Group({ layer, group, scene, catalog }, {translator,  projectActions, groupsActions}) {  
  
  return (
    <g
      data-element-root
      data-prototype={group.prototype}
      data-id={group.id}
      data-selected={group.selected}
      data-layer={layer.id}
      style={group.selected ? { cursor: 'move' } : {}}
      transform={`translate(${group.x},${group.y}) rotate(${group.rotation})`}
    >
      <If condition={group.selected}>
        <g
          data-element-root
          data-prototype={group.prototype}
          data-id={group.id}
          data-selected={group.selected}
          data-layer={layer.id}
          data-part="rotation-anchor"
        >
          <circle cx={cx} cy={cy} r={radius} style={STYLE_CIRCLE_BARY_CENTER}>
            <title>{translator.t('Group\'s Barycenter')}</title>
          </circle>
          <circle cx={cx} cy={cy} r={15} style={STYLE_CIRCLE} onClick={() => {
              projectActions.setMode(MODE_IDLE);
              // projectActions.unselectAll();
            }}>
            <title>{`Click to stop moving group`}</title>
          </circle>
        </g>
      </If>
    </g>
  )
}

Group.propTypes = {
  group: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};

Group.contextTypes = {
  translator: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  groupsActions: PropTypes.object.isRequired,
};