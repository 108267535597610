import { Area } from '../class/export';
import { DETECT_AND_UPDATE_AREA, SELECT_AREA } from '../constants';

export default function (state, action) {
  switch (action.type) {
    case SELECT_AREA:
      return Area.select(state, action.layerID, action.areaID).updatedState;
    case DETECT_AND_UPDATE_AREA:
        return Area.detectAndUpdateArea(state, action.layerID).updatedState;
    default:
      return state;
  }
}
